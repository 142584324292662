import React from 'react';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import Grid from '../../Grid';

const ImageGallery = ({ galleryImages }) => {
  return (
    <section className="image-gallery">
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          <ScrollMenu itemClassName="gallery-item">
            {galleryImages.map((image, index) => (
              <>
                {image.url && (
                  <img src={image.fluid?.src || image.url} alt={image.alt || ''} className="w-full image" />
                )}
              </>
            ))}
          </ScrollMenu>
        </Grid.Col>
      </Grid>
    </section>
  );
};

export default ImageGallery;
