import React from 'react';
import { RichText } from 'prismic-reactjs';
import { InView } from 'react-intersection-observer';

import Grid from '../../Grid';

const FAQ = ({ faq, setActiveSection }) => {
  const [activeFaqId, setActiveFaqId] = React.useState();

  const onSectionEnter = (inView, entry) => {
    if (inView) {
      setActiveSection(entry.target.id);
    }
  };

  return (
    <InView
      onChange={onSectionEnter}
      rootMargin="-50% 0px"
      threshold={0}
      id="faqs"
      as="section"
      className="faq page-faq"
    >
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          <h2 className="faq-heading">FAQs</h2>

          <div className="faq-body">
            {faq.map((faq, index) => (
              <div
                key={index}
                className={`faq-list ${index === activeFaqId ? 'active' : ''}`}
                onClick={() => setActiveFaqId(index === activeFaqId ? null : index)}
              >
                <h6 className={`faq-title ${index === activeFaqId ? 'current' : ''}`}>
                  {RichText.asText(faq.question?.raw)}
                </h6>

                <div
                  className="faq-content"
                  style={{
                    display: index === activeFaqId ? 'block' : 'none',
                  }}
                >
                  <RichText render={faq.answer?.raw} />
                </div>
              </div>
            ))}
          </div>
        </Grid.Col>
      </Grid>
    </InView>
  );
};

export default FAQ;
