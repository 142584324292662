import Box from '@mui/material/Box';
import React, { useState, useEffect, useRef } from 'react';

const ReadMoreOrLess = ({ maxLines = 5, children, className, sx }) => {
  const [showMore, setShowMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef(null);
  useEffect(() => {
    if (contentRef.current) {
      const { clientHeight, scrollHeight } = contentRef.current;
      setIsTruncated(clientHeight < scrollHeight);
    }
  }, [contentRef]);
  const toggleText = showMore ? 'Read less' : 'Read more';
  const readMoreButton = (
    <button
      className="btn-unstyled"
      style={{
        fontWeight: '600',
      }}
      onClick={() => setShowMore(!showMore)}
    >
      {toggleText}
    </button>
  );
  return (
    <>
      <Box
        ref={contentRef}
        className={className}
        sx={{
          display: showMore ? 'block' : '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: maxLines,
          overflow: 'hidden',
          ...sx,
        }}
      >
        {children}
      </Box>
      {isTruncated && <>{readMoreButton}</>}
    </>
  );
};

export default ReadMoreOrLess;
