import React, { useContext } from 'react';
import { RichText } from 'prismic-reactjs';
import { InView } from 'react-intersection-observer';

import Grid from '../../Grid';
import { isBrowser } from '../../../utils/isBrowser';
import ReadMoreOrLess from '../../ReadMoreOrLess';
import ArrowLeftIcon from '../../../images/icon-arrow-left-short.svg';
import ArrowRightIcon from '../../../images/icon-arrow-right-short.svg';
import { AppContext } from '../../../apollo/wrap-root-element';
import { currencySymbolMap } from '../../Header/currencyswitcher/CurrencySwitcher';

// Since OwlCarousel is not SSR compatible, we need to check if we are in the browser
const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const Overview = ({ pricePerPerson, sliderImages, description, setActiveSection }) => {
  const onSectionEnter = (inView, entry) => {
    if (inView) {
      setActiveSection(entry.target.id);
    }
  };

  const { currency, currenciesRate } = useContext(AppContext);

  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);

  return (
    <InView
      onChange={onSectionEnter}
      rootMargin="-50% 0px"
      threshold={0}
      id="overview"
      className="overview"
      as="section"
    >
      <Grid>
        <Grid.Col
          md="2 / span 5"
          xs="1 / span 2"
          sx={{
            maxHeight: {
              xs: 'auto',
              md: '375px',
            },
            overflowY: 'auto',
            padding: {
              xs: '0 15px',
              md: '0 6px 0 0',
            },
          }}
        >
          <ReadMoreOrLess maxLength={320} className="description">
            <RichText render={description} />
          </ReadMoreOrLess>

          <div className="price-wrapper">
            <h4>Starts from</h4>
            <div className="price-wrapper-inner">
              <span
                style={{
                  width: '155px',
                }}
              >
                Single occupancy:
              </span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="price">
                  {currencySymbolMap[currency] || '$'}
                  {parseFloat((selectedRate.rate * pricePerPerson.singleOccupancy).toFixed(2))}
                </span>
                <span
                  style={{
                    lineHeight: '135.04%',
                  }}
                >
                  /per person
                </span>
              </div>
            </div>

            <div
              className="price-wrapper-inner"
              style={{
                marginTop: '12px',
              }}
            >
              <span
                style={{
                  width: '155px',
                }}
              >
                Double occupancy:
              </span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="price">
                  {currencySymbolMap[currency] || '$'}
                  {parseFloat((selectedRate.rate * pricePerPerson.doubleOccupancy).toFixed(2))}
                </span>
                <span
                  style={{
                    lineHeight: '135.04%',
                  }}
                >
                  /per person
                </span>
              </div>
            </div>
          </div>
        </Grid.Col>

        <Grid.Col
          md="auto / span 5"
          xs="1 / span 2"
          sx={{
            order: {
              xs: '-1',
              md: '1',
            },
          }}
        >
          <OwlCarousel
            className="carousel-stage owl-carousel la-slider"
            responsiveClass
            navigation="true"
            navText={[
              `<img src=${ArrowLeftIcon} alt="Arrow left icon" />`,
              `<img src=${ArrowRightIcon} alt="Arrow right icon" />`,
            ]}
            responsive={{
              0: {
                items: 1,
                nav: true,
              },
              768: {
                items: 1,
                nav: false,
              },

              1200: {
                items: 1,
                nav: true,
              },
            }}
          >
            {sliderImages &&
              sliderImages.map(({ image }, index) => (
                <div key={index} className="overview-carousel-item">
                  {image?.url && <img src={image.fluid?.src || image.url} alt={image.alt || ''} />}
                </div>
              ))}
          </OwlCarousel>
        </Grid.Col>
      </Grid>
    </InView>
  );
};

export default Overview;
