import React, { useRef, useEffect, useState } from 'react';

import Grid from '../../Grid';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import { RightArrow, LeftArrow } from './Arrows';
import { AppContext } from '../../../apollo/wrap-root-element';

const SectionNav = ({ activeSection, hasPreExtension, hasPostExtension }) => {
  const { scrolledDirection, scrollPosition, deviceIsTab } = React.useContext(AppContext);

  const sections = [
    {
      title: 'Overview',
      id: 'overview',
    },
    {
      title: 'Included & Not Included',
      id: 'included-not-included',
    },
    {
      title: 'Main Itinerary',
      id: 'main-itinerary',
    },
    {
      title: 'FAQs',
      id: 'faqs',
    },
  ];

  if (hasPostExtension) {
    sections.splice(3, 0, {
      title: 'Post Extension Tour',
      id: 'post-extended-itinerary',
    });
  }

  if (hasPreExtension) {
    sections.splice(3, 0, {
      title: 'Pre Extension Tour',
      id: 'pre-extended-itinerary',
    });
  }

  const apiRef = useRef({});

  useEffect(() => {
    // Scroll to active section
    const scrollToItem = apiRef.current?.getItemElementById(activeSection);
    apiRef.current?.scrollToItem?.(scrollToItem, 'smooth', 'start');
  }, [activeSection]);

  return (
    <div
      className={`section-nav-container  ${
        deviceIsTab && scrolledDirection ? 'on-up-scrolling' : 'on-down-scrolling'
      }`}>
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          <div
            className="section-nav"
            style={{
              width: '100%',
              position: 'relative',
            }}>
            <ScrollMenu apiRef={apiRef} LeftArrow={LeftArrow} RightArrow={RightArrow} itemClassName="section-nav-item">
              {sections.map((section) => (
                <div key={section.id} className={section.id === activeSection ? 'active' : ''}>
                  <button
                    className={`nowrap btn-unstyled ${section.id === activeSection ? 'active' : ''}`}
                    onClick={() => {
                      const target = document.getElementById(section.id);
                      target.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }}>
                    {section.title}
                  </button>
                </div>
              ))}
            </ScrollMenu>
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default SectionNav;
