import React from 'react';
import ReactMapBoxGl, { Marker, ZoomControl } from 'react-mapbox-gl';

import PopUpCard from './PopupCard';
import { formatCoordinate } from '../../../../../utils/formatCoordinates';
import MarkerIcon from '../../../../../images/mapbox-marker-icon-red.svg';

const initialZoomLevel = 4;

const Mapbox = ReactMapBoxGl({
  container: 'map',
  accessToken: process.env.GATSBY_MAPBOX_API_KEY,
  scrollZoom: false,
});

const Map = ({ locationPins }) => {
  const [popup, setPopup] = React.useState(null);

  const [zoom, setZoom] = React.useState(initialZoomLevel);
  const [center, setCenter] = React.useState(formatCoordinate(locationPins[0]?.coordinates));

  const onMapUpdate = (map) => {
    const newZoom = map.getZoom();
    const newCenter = map.getCenter();

    if (map.getZoom() !== zoom) {
      setZoom(newZoom);
    }
    if (newCenter[0] === center[0] && newCenter[1] === center[1]) {
      setCenter(newCenter);
    }
  };

  return (
    <div className="map-wrapper">
      <link href="https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css" rel="stylesheet" />
      <Mapbox
        style="mapbox://styles/lineagejourney/clg25ktcd004h01myq4a5x1hv"
        containerStyle={{
          height: 'calc(100vh - 255px)',
          width: '100%',
        }}
        movingMethod="easeTo"
        zoom={[zoom]}
        onZoomEnd={onMapUpdate}
        onMoveEnd={onMapUpdate}
        onPitchEnd={onMapUpdate}
        center={center}
        onClick={() => setPopup(null)}
      >
        <ZoomControl position="top-right" />
        {locationPins.map((location, index) => (
          <Marker
            key={index}
            coordinates={formatCoordinate(location.coordinates)}
            anchor="center"
            onMouseEnter={(e) => {
              setPopup(location);
            }}
            onClick={(e) => {
              setPopup(location);
            }}
            onMouseLeave={(e) => {
              setPopup(null);
            }}
          >
            <img src={MarkerIcon} alt="Marker Icon" />
          </Marker>
        ))}

        <PopUpCard
          description={popup?.markerDescription}
          name={popup?.name}
          coordinates={popup?.coordinates}
          image={popup?.markerImage?.fluid.src}
        />
      </Mapbox>
    </div>
  );
};

export default Map;
