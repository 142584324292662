import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import ArrowLeft from '../../../../images/arrow-left-2.svg';

export const LeftArrow = () => {
  const { scrollPrev, isFirstItemVisible, visibleElements } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!visibleElements.length && isFirstItemVisible);

  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <div className={`arrows arrows-left ${disabled ? 'hidden' : ''}`}>
      <div className={`arrows-inner ${disabled ? 'hidden' : ''}`} onClick={() => scrollPrev()}>
        <img src={ArrowLeft} alt="left arrow" />
      </div>
    </div>
  );
};

export const RightArrow = () => {
  const { scrollNext, isLastItemVisible, visibleElements } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!visibleElements.length && isLastItemVisible);

  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <div className={`arrows arrows-right ${disabled ? 'hidden' : ''}`}>
      <div className="arrows-inner" onClick={() => scrollNext()}>
        <img
          src={ArrowLeft}
          alt="left arrow"
          style={{
            rotate: '180deg',
          }}
        />
      </div>
    </div>
  );
};
