import React from 'react';
import { InView } from 'react-intersection-observer';

import Itinerary from './Itinerary';

const PreExtendedItineraryHeader = ({ heading }) => <h5 className="title">{heading}</h5>;

const PreExtendedItinerary = ({
  setActiveSection,
  preExtensionStartDate,
  preExtendedItineraryData,
  locationPins,
  isPrivateTour,
}) => {
  const onSectionEnter = (inView, entry) => {
    if (inView) {
      setActiveSection(entry.target.id);
    }
  };

  const preExtensionLocationPins = locationPins.filter((locationPin) => locationPin.isPreExtension);

  const preExtendedItineraryDataWithDays = preExtendedItineraryData.map((itinerary, index) => {
    return {
      ...itinerary,
      day: index + 1,
    };
  });

  return (
    <InView
      onChange={onSectionEnter}
      rootMargin="-50% 0px"
      threshold={0}
      id="pre-extended-itinerary"
      className="itinerary"
      as="section">
      <Itinerary
        itineraryType="preExtended"
        itineraryData={preExtendedItineraryDataWithDays}
        tourStartDate={preExtensionStartDate}
        locationPins={preExtensionLocationPins}
        header={
          <PreExtendedItineraryHeader
            heading={isPrivateTour ? 'Pre-Extension Tour (June 2nd-4th 2025)' : 'Pre Extended Itinerary'}
          />
        }
      />
    </InView>
  );
};

export default PreExtendedItinerary;
