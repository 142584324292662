import React from 'react';
import { InView } from 'react-intersection-observer';

import Grid from '../../Grid';
import IconChecked from '../../../images/icon-check.svg';
import IconCrossed from '../../../images/icon-cross2.svg';

const ListItemChecked = ({ children }) => (
  <li>
    <img src={IconChecked} alt="Checked Icon" role="presentation" />
    <span>{children}</span>
  </li>
);

const ListItemCrossed = ({ children }) => (
  <li>
    <img src={IconCrossed} alt="Crossed Icon" role="presentation" />
    <span>{children}</span>
  </li>
);

const WhatsIncludedAndNotIncluded = ({ whatsIncludedList, whatsNotIncludedList, setActiveSection }) => {
  const onSectionEnter = (inView, entry) => {
    if (inView) {
      setActiveSection(entry.target.id);
    }
  };

  return (
    <InView
      onChange={onSectionEnter}
      rootMargin="-50% 0px"
      threshold={0}
      id="included-not-included"
      as="section"
      className="whatsIncludedNotIncluded"
    >
      <Grid>
        <Grid.Col md="2 / span 5" xs="1 / span 2">
          <h2>What is included in this tour?</h2>
          <p>Items that are included in the cost of our tour price</p>
          <ul>
            {whatsIncludedList.map(({ whatsIncluded }, index) => (
              <ListItemChecked key={index}>{whatsIncluded}</ListItemChecked>
            ))}
          </ul>
        </Grid.Col>

        <Grid.Col md="auto / span 5" xs="1 / span 2">
          <h2>What is not included in this tour?</h2>
          <p>Items that are not included in the cost of our tour price</p>
          <ul>
            {whatsNotIncludedList.map(({ whatsNotIncluded }, index) => (
              <ListItemCrossed key={index}>{whatsNotIncluded}</ListItemCrossed>
            ))}
          </ul>
        </Grid.Col>
      </Grid>
    </InView>
  );
};

export default WhatsIncludedAndNotIncluded;
