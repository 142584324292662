import React from 'react';
import Box from '@mui/material/Box';
import { Tab } from '@headlessui/react';

const TabGroup = ({ children, className }) => {
  return (
    <Tab.Group as="div" className={`tab-group ${className}`}>
      {children}
    </Tab.Group>
  );
};

const TabList = ({ children, className }) => {
  return <Tab.List className={`tab-list ${className}`}>{children}</Tab.List>;
};

const TabItem = ({ children, className, contentClassName }) => {
  return (
    <Tab className={`btn-unstyled tab-wrapper ${className || ''}`}>
      {({ selected }) => (
        <Box
          sx={{
            height: '100%',
          }}
          className={`tab w-full ${contentClassName} ${selected ? 'active' : ''}`}
        >
          {children}
        </Box>
      )}
    </Tab>
  );
};

// Panels are the content of each tab

// Container for all panels
const TabPanels = ({ children, className }) => {
  return <Tab.Panels className={className}>{children}</Tab.Panels>;
};

// Individual panel
const TabPanel = ({ children, className }) => {
  return <Tab.Panel className={`tab-panel ${className}`}>{children}</Tab.Panel>;
};

const Tabs = Object.assign(TabGroup, {
  List: TabList,
  Tab: TabItem,
  Panels: TabPanels,
  Panel: TabPanel,
});

Tabs.displayName = 'Tabs';

export default Tabs;
