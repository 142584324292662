import React from 'react';
import { InView } from 'react-intersection-observer';

import Itinerary from './Itinerary';

const PostExtendedItineraryHeader = () => <h5 className="title">Post Extended Itinerary</h5>;

const PostExtendedItinerary = ({
  setActiveSection,
  itineraryData: mainItineraryData,
  postExtensionStartDate,
  postExtendedItineraryData,
  locationPins,
}) => {
  const onSectionEnter = (inView, entry) => {
    if (inView) {
      setActiveSection(entry.target.id);
    }
  };

  const postExtensionLocationPins = locationPins.filter((locationPin) => locationPin.isPostExtension);

  const mainItineraryDays = mainItineraryData.length - 1; // -1 because we don't want to count the departure day
  const postExtendedItineraryDataWithDays = postExtendedItineraryData.map((itinerary, index) => {
    return {
      ...itinerary,
      day: mainItineraryDays + index + 1,
    };
  });

  return (
    <InView
      onChange={onSectionEnter}
      rootMargin="-50% 0px"
      threshold={0}
      id="post-extended-itinerary"
      className="itinerary"
      as="section"
    >
      <Itinerary
        itineraryType="postExtended"
        itineraryData={postExtendedItineraryDataWithDays}
        tourStartDate={postExtensionStartDate}
        locationPins={postExtensionLocationPins}
        header={<PostExtendedItineraryHeader />}
      />
    </InView>
  );
};

export default PostExtendedItinerary;
